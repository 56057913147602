import React from "react";

function FooterAdmin() {
  return (
    <footer className="footer_area f_bg_color">
      <div className="footer_top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="f_widget subscribe_widget">
                <a href="/" className="f_logo">
                  <img
                    src="/assets/img/logo.png"
                    srcSet="/assets/img/logo-2x.png 2x"
                    alt=""
                  />
                </a>

                <ul className="list-unstyled f_social_icon">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/etrune.jewels"
                      className="text-gold"
                    >
                      <i className="social_facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/etrune.jewels/"
                      className="text-gold"
                    >
                      <i className="social_instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div
                className="f_widget link_widget pl_30"
                data-wow-delay="0.2s"
              >
                <h3 className="f_title text-gold text-etrune">Company</h3>
                <ul className="list-unstyled link_list">
                  <li>
                    <a target="_blank" href="https://etrune.com/about">
                      About us
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://etrune.com/pages/our-materials">
                      Materials
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://etrune.com/pages/our-process">
                      Our process
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://etrune.com/blogs/articles">
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div
                className="f_widget link_widget"
                data-wow-delay="0.4s"
              >
                <h3 className="f_title text-gold text-etrune">Support</h3>
                <ul className="list-unstyled link_list">
                  <li>
                    <a target="_blank" href="https://etrune.com/contact">
                      Contact us
                    </a>
                  </li>
                  <li>
                    <button
                      className="p-0"
                      onClick={() => {
                        const message = `Hello! I’d like to chat with an agent for immediate help.`;
                        window.open(
                          `https://wa.me/19295886646?text=${encodeURIComponent(
                            message
                          )}`,
                          "_blank"
                        );
                      }}
                    >
                      Live chat
                    </button>
                  </li>
                  <li>
                    <button
                      className="p-0"
                      onClick={() => {
                        const message = `Hi ÊTRUNE team! I need support with my gemstone purchase.`;
                        window.open(
                          `https://wa.me/19295886646?text=${encodeURIComponent(
                            message
                          )}`,
                          "_blank"
                        );
                      }}
                    >
                      Gemstone purchase
                    </button>
                  </li>
                  <li>
                    <button
                      className="p-0"
                      onClick={() => {
                        const message = `Hi! I need assistance with my ÊTRUNE account and order history.`;
                        window.open(
                          `https://wa.me/19295886646?text=${encodeURIComponent(
                            message
                          )}`,
                          "_blank"
                        );
                      }}
                    >
                      Account & orders
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div
                className="f_widget link_widget"
                data-wow-delay="0.6s"
              >
                <h3 className="f_title text-gold text-etrune">Legal</h3>
                <ul className="list-unstyled link_list">
                  <li>
                    <a target="_blank" href="https://etrune.com/terms">
                      Terms & conditions
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://etrune.com/privacy">
                      Privacy policy
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://support.etrune.com/legal">
                      Customer service
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://etrune.com/ethics">
                      Ethics & compliance
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="subfooter" className="row border-top pt-3 mt-5">
            <div className="col-12 d-flex justify-content-between">
              <p className="">
                © 2024{" "}
                <a
                  translate="true"
                  href="https://www.virtualcapitalofamerica.com/"
                  target="_blank"
                >
                  Etrune LLC
                </a>
                . rights reserved
              </p>
              <div>
                <a
                  className="mx-2"
                  translate="true"
                  href="https://etrune.com/terms"
                >
                  Terms of Use
                </a>
                <a
                  className="mx-2"
                  translate="true"
                  href="https://etrune.com/privacy"
                >
                  Privacy Policy
                </a>
                <a
                  className="mx-2"
                  translate="true"
                  href="https://etrune.com/ethics"
                >
                  Ethics & compliance
                </a>
              </div>
            </div>
            <div className="col-12">
              <p>
                ETRUNE is committed to offering unique, high-luxury jewelry
                through ethical and environmentally sustainable practices. Our
                pieces and gemstones are responsibly sourced and certified as
                conflict-free, backed by internationally recognized
                certifications. We uphold transparency at every level of our
                supply chain, from gemstone acquisition to the sale of each
                piece. ÊTRUNE adheres to all applicable regulations and laws
                aimed at preventing money laundering and terrorist financing, in
                accordance with the U.S. Bank Secrecy Act, the European Union’s
                Anti-Money Laundering Regulations, and relevant laws in the
                markets where we operate. Transactions exceeding regulatory
                thresholds may require additional identity verification and
                source of funds documentation. For further details, please
                consult our Compliance and Anti-Money Laundering Policies. The
                value of our jewelry and gemstones may fluctuate due to market
                conditions, and purchasing our pieces should not be interpreted
                as a financial investment or speculative asset. ÊTRUNE does not
                provide guarantees of appreciation in value or resale
                opportunities. Clients acknowledge that they are acquiring
                luxury items for their sentimental and artistic value, not as
                investment instruments. While we make every effort to ensure the
                quality and authenticity of our products, ÊTRUNE assumes no
                responsibility for economic losses resulting from market
                fluctuations, unforeseen deterioration of the piece, or misuse
                by the client.
              </p>
              <p>
                We are committed to protecting our clients' privacy and data
                security in compliance with the General Data Protection
                Regulation (GDPR) in Europe and other applicable privacy laws.
                Your data will be handled with strict confidentiality and will
                be used solely to enhance your experience and fulfill regulatory
                obligations. Please refer to our Privacy Policy for additional
                details. Each ÊTRUNE piece includes a unique certificate that
                records the origin, authenticity, and quality of its materials.
                Leveraging blockchain technology, this certification is
                immutable and accessible at any time. However, it does not
                guarantee future value and does not constitute an endorsement of
                appreciation. For any additional inquiries regarding our terms
                of use and acquisition policies, please contact our customer
                service team, available 24/7. You may also refer to our Terms of
                Use and Refund Policy, accessible on this website. For clients
                in the U.S., Europe, and Asia, ÊTRUNE provides online dispute
                resolution procedures in compliance with regional consumer
                protection regulations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterAdmin;
