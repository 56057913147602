import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import SidebarSearch from "../../components/sidebar/SidebarSearch";
import { ItemService } from "../../services/index";
import {
  FormControl,
  Select,
  MenuItem,
  Pagination,
  Card,
  CardActionArea,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Chip,
} from "@mui/material";

async function getItems(params) {
  const entityService = new ItemService();
  const entityResponse = await entityService.getByParameters(params);

  return entityResponse?.result ?? [];
}

function dataLoaded({ result }) {
  var event = new Event("page-loaded");
  window.dispatchEvent(event);

  var event = new CustomEvent("data-loaded", {
    detail: { query: result?.query || {} },
  });
  window.dispatchEvent(event);
}

function SearchResults() {
  const { setPageName } = useOutletContext();
  const [entities, setEntities] = useState([]);
  const [sortBy, setSortBy] = React.useState("most-relevant");
  const [pagination, setPagination] = useState({
    pageSize: 12,
    page: 1,
    totalItems: 0,
    totalPages: 0,
  });
  const params = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );
  let searching = false;

  const handleChange = (event) => {
    setSortBy(event.target.value);
  };

  const getItemsByQuery = async () => {
    const result = await getItems({
      queryselector: "text",
      search: params.q,
      pageSize: pagination.pageSize,
      page: pagination.page,
    });

    setItems(result ?? {});
    dataLoaded({ result });
  };

  const getAllItems = async () => {
    const result = await getItems({
      queryselector: "all",
      search: params.q,
      pageSize: pagination.pageSize,
      page: pagination.page,
    });

    setItems(result ?? {});
    dataLoaded({});
  };

  const setItems = (result) => {
    setEntities(result?.items ?? []);

    handlePaginationDataChange("totalItems", result?.totalItems ?? 0);
    handlePaginationDataChange("totalPages", result?.totalPages ?? 0);
  };

  const handlePaginationDataChange = (fieldName, data) => {
    setPagination((prevUserData) => ({
      ...prevUserData,
      [fieldName]: data,
    }));
  };

  const handleChangePage = (event, value) => {
    handlePaginationDataChange("page", value ?? 1);
  };

  const search = async () => {
    if (!searching) {
      searching = true;
      if (!params.q || params.q === "all") {
        await getAllItems();
      } else {
        await getItemsByQuery();
      }
      searching = false;
    }
  };

  /**
   * On page loaded => Set page name
   */
  useEffect(() => {
    console.log("changed page");
    search();
  }, [pagination.page]);

  /**
   * On page loaded => Get results
   */
  useEffect(() => {
    setPageName("Results page");
    search();
  }, []);

  return (
    <>
      <section className=" bg-light d-flex w-100">
        {/* Left sidebar */}
        <SidebarSearch />

        {/* Content */}
        <section className="col-12 col-xl-9 mx-auto">
          <section className="d-flex flex-wrap mt-3">
            <header className="col-12 d-flex flex-column flex-md-row justify-content-between">
              <section className="d-flex flex-grow-1">
                <p>Sort by</p>
                <FormControl
                  fullWidth
                  variant="standard"
                  className="col-8 col-md-2 mx-2"
                >
                  <Select
                    labelId="sort-by-label"
                    id="sort-by"
                    value={sortBy}
                    onChange={handleChange}
                  >
                    <MenuItem value="most-relevant">Most relevant</MenuItem>
                  </Select>
                </FormControl>
              </section>
              <section>
                <p>
                  {pagination?.pageSize} of {pagination?.totalItems} items
                </p>
              </section>
            </header>
          </section>

          <section className="d-flex flex-wrap mt-3">
            {entities?.map((entity) => (
              <section
                className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-3 d-flex flex-column"
                key={entity.id}
              >
                <a
                  href={`/search/details?id=${entity.id}`}
                  target="_blank"
                  className="bold flex-grow-1 d-flex flex-column"
                >
                  <Card style={{ borderRadius: "16px" }} className="flex-grow-1">
                    <CardHeader
                      className="overflow-hidden d-block p-2 p-md-3"
                      title={
                        <h6 className="mb-0 text-truncate font-weight-normal text-etrune">
                          {`${entity?.name} ${entity?.properties?.cut?.name} ${entity?.properties?.cut?.style}`}
                        </h6>
                      }
                      subheader={
                        <small className="text-muted">{`Report: ${entity?.metadata?.report}`}</small>
                      }
                    />
                    <CardMedia
                      component="img"
                      className={`card-img-top object-fit-contain search-image-result p-2 p-md-4 ${
                        entity?.media?.length > 0 ? "" : "opacity-07"
                      }`}
                      image={
                        entity?.media?.[0]?.url ||
                        "/assets/img/preview-unavailable-minimal.png"
                      }
                      alt={entity?.name}
                    />
                    <CardContent className="d-flex flex-column p-2 p-md-3">
                      <section className="badges">
                        {entity?.properties?.size?.trade_measure && (
                          <Chip
                            label={
                              <small className="text-muted">
                                {entity?.properties?.size?.trade_measure}
                              </small>
                            }
                            variant="outlined"
                            className="mr-2"
                          />
                        )}

                        {entity?.properties?.weight?.trade_weight && (
                          <Chip
                            label={
                              <small className="text-muted">
                                {entity?.properties?.weight?.trade_weight}
                              </small>
                            }
                            variant="outlined"
                          />
                        )}
                      </section>
                      <section className="d-flex flex-column flex-md-row justify-content-between border-top mt-3 pt-3 flex-grow-1">
                        <section className="d-flex flex-column flex-grow-1">
                          {entity?.properties?.gemological_composition
                            ?.is_lab_grown ? (
                            <small className="text-muted text-center text-md-left mb-2 mb-md-0">
                              Lab Grown
                            </small>
                          ) : (
                            <small className="text-muted text-center text-md-left mb-2 mb-md-0">
                              Natural
                            </small>
                          )}
                          <div className="d-flex justify-content-center justify-content-md-start">
                            <h6 className="mb-0 text-etrune justify-content-center">
                              <span className="text-center text-md-left">
                                {entity?.properties?.price?.current_price
                                  ? `$${new Intl.NumberFormat("en-US").format(
                                      entity.properties.price.current_price
                                    )}`
                                  : "Price upon request"}
                              </span>
                            </h6>
                            <small className="ml-2 text-etrune">
                              {entity?.properties?.price?.asset_symbol ||
                                "\u00A0"}
                            </small>
                          </div>
                        </section>
                        <section className="d-flex justify-content-md-between justify-content-center my-2">
                          <button className="btn btn-cta my-auto">
                            Details
                          </button>
                        </section>
                      </section>
                    </CardContent>
                  </Card>
                  {/* <article className="card border-0">
                    <small className="text-muted text-truncate">
                      Report: {entity?.metadata?.report}
                    </small>
                    <section className="bg-white">
                      <img
                        src={
                          entity?.media?.[0]?.url ||
                          "/assets/img/preview-unavailable.jpg"
                        }
                        className={`card-img-top object-fit-contain search-image-result ${
                          entity?.media?.length > 0 ? "" : "opacity-07"
                        }`}
                        alt={entity?.name}
                        height="300"
                      />
                    </section>

                    <section className="card-body px-0 pt-2">
                      <h6 className="mb-0 text-truncate font-weight-normal">
                        {entity?.name} {entity?.properties?.cut?.name}{" "}
                        {entity?.properties?.cut?.style}
                      </h6>
                      <p className="mb-0">
                        <span className="badge badge-light px-2">
                          {entity?.properties?.size?.trade_measure}
                        </span>

                        <span className="badge badge-light px-2">
                          {entity?.properties?.weight?.trade_weight}
                        </span>
                        {entity?.properties?.gemological_composition
                          ?.is_lab_grown ? (
                          <span className="badge badge-info px-2">
                            Lab Grown
                          </span>
                        ) : (
                          <span className="badge badge-success px-2">
                            Natural
                          </span>
                        )}
                      </p>
                    </section>
                  </article> */}
                </a>
              </section>
            ))}
          </section>

          <section className="d-flex justify-content-center mb-4 mt-3">
            <Pagination
              count={pagination.totalPages ?? 1}
              onChange={handleChangePage}
              siblingCount={2}
              boundaryCount={2}
            />
          </section>
        </section>
      </section>
    </>
  );
}

export default SearchResults;
